<div class="cm-quick-bar theme-bg">
	<div class="cm-quick-bar theme-bg">
		<div class="share addthis_share" [ngClass]="{ active: shareButtonsActive }">
			<div class="addthis_share_button my-1" *ngFor="let btn of shareButtons">
				<div class="share-button {{ btn.service }}" (click)="share(btn)">
					<fa-icon [icon]="btn.icon" [fixedWidth]="btn.iconFixedWidth"></fa-icon>
				</div>
			</div>
		</div>
		<cm-button-grid [gridData]="buttons$ | async" [classes]="['quick-button']"></cm-button-grid>
	</div>

	<cm-modal [show]="showUserViewing" (showChange)="showUserViewing = $event">
		<ng-template cmModal>
			<cm-quick-admin></cm-quick-admin>
		</ng-template>
	</cm-modal>

	<cm-modal [show]="showInfo" (showChange)="showInfo = $event">
		<ng-template cmModal>
			<div class="row">
				<div
					class="h1 col-12 text-white font-normal font-weight-normal text-uppercase flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					style="background-color: #003a5d"
				>
					Search Info
				</div>
				<div class="col-12">
					<h3>To search for listings:</h3>
					<p>
						In the Quick Search bar you can enter a variety of information to help find the right property
						for you. You can narrow your search by selecting type, location, size, and desired features.
						Once you have all of the options selected, you can save your search to browse again later.
					</p>
					<hr />
					<h3>To save a search:</h3>
					<ul>
						<li>
							Click on the Save Search Icon
							<img class="info-icon" src="https://dms.rvimg.com/s3static/22/save-icon.png" />
						</li>
						<li>
							You can create a name for a new search or select a previous search to update from the
							drop-down menu
						</li>
						<li>Then click the Save Search button</li>
					</ul>
					<hr />
					<h3>To load a previous search:</h3>
					<ul>
						<li>
							Click on the Load Search Icon
							<img class="info-icon" src="https://dms.rvimg.com/s3static/22/load-icon.png" />
						</li>
						<li>Select the name of any previously saved searches from the drop-down menu.</li>
						<li>Then click the Load Search button</li>
					</ul>
				</div>
			</div>
		</ng-template>
	</cm-modal>
</div>
