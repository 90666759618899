import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import Version from "@core/app/common/version";
import { IPageData } from "@model/page-data";
import { of } from "rxjs";
import { map } from "rxjs/operators";

declare const __DEFINE_VERSION: string;

@Injectable({ providedIn: "root" })
export class ConfigService {
	/**
	 * Whether or not API requests are allowed to be cached or not
	 */
	cachingEnabled = true;

	cdnRootPath: any = null;

	/**
	 * Google Analytics Code
	 */
	googleAnalyticsCode: string | null = null;

	/**
	 * S3 Static Url for Images
	 */
	imgStaticUrl = "s3static/";

	/**
	 * Scripts available for loading dynamically
	 */
	scripts = [
		{
			key: "maps",
			enabled: false,
			src:
				"https://maps.googleapis.com/maps/api/js?key=AIzaSyDK6RybE29BiXD8VPd_BtKeE0LuvjaCcYA&cacheBuster=" +
				new Date().getTime(),
		},
		{
			key: "wistia",
			enabled: false,
			src: "//fast.wistia.com/assets/external/E-v1.js",
		},
		{
			key: "videopal",
			enabled: false,
			src: "//videopal.me/js/vp_player.min.js?v=1.1.15",
		},
		{
			key: "bugherd",
			enabled: false,
			src: "https://www.bugherd.com/sidebarv2.js?apikey=f3kwqd1bvpboos8pvdeb4w",
		},
	];

	/**
	 * Any params listed here will be cleared automatically from route checking
	 */
	specialParams = [""];

	/**
	 * Static URL
	 */
	staticUrl = "";

	/**
	 * Table Config
	 */
	table = {
		/**
		 * Column lengths are multiplied by this factor to get width of the column
		 */
		columnLengthMultiplier: 8,
		/**
		 * Column width can't exceed this number
		 */
		columnMaxLength: 150,
		/**
		 * Default Pagination Limit
		 */
		paginationLimit: 25,
	};

	/**
	 * Version of bundled code
	 */
	version = Version.fromString(__DEFINE_VERSION);

	private settings =
		typeof window !== "undefined" && window.sessionStorage.hasOwnProperty("settings")
			? JSON.parse(window.sessionStorage.getItem("settings")!)
			: {};

	constructor(
		private http: HttpClient,
		private transfer: TransferRxService,
		@Inject(PLATFORM_ID) platformId: object,
		@Inject("PAGE_DATA") private pageData: IPageData,
	) {
		this.cdnRootPath = this.pageData.appInfo.data.cdnRootPath;
		this.staticUrl =
			this.pageData.appInfo.data.cdnRootPath + this.imgStaticUrl + this.pageData.appInfo.data.siteConfigId + "/";
		this.googleAnalyticsCode = this.pageData.appInfo.data.googleAnalyticsCode;
	}

	/**
	 * Fetches a specified admin setting
	 */
	getSetting(settingName: any) {
		if (this.settings.hasOwnProperty(settingName)) {
			return Promise.resolve(this.settings[settingName]);
		} else {
			return this._getSetting(settingName).then((setting) => {
				if (setting) {
					this.settings[settingName] = setting;
					if (typeof window !== "undefined") {
						try {
							window.sessionStorage.setItem("settings", JSON.stringify(this.settings));
						} catch (e) {
							console.error("Storage quota exceeded!", e);
						}
					}
				}

				return setting;
			});
		}
	}

	getSetting$(settingName: any) {
		if (this.settings.hasOwnProperty(settingName)) {
			return of(this.settings[settingName]);
		} else {
			return this.transfer.transfer$("Setting:" + settingName, () =>
				this.http
					.get(
						"/api/admin/setting/find/" +
							settingName +
							"?appId" +
							this.pageData.appInfo.data.appId +
							"&siteConfigId=" +
							this.pageData.appInfo.data.siteConfigId,
					)
					.pipe(map((response: any) => (response.success ? response.admin_setting : null))),
			);
		}
	}

	/**
	 * Retrieves an Admin Setting from the API
	 */
	private async _getSetting(setting: any) {
		const response = (await this.http.get("/api/admin/setting/find/" + setting).toPromise()) as any;

		if (response.success) {
			return response.admin_setting;
		} else {
			return null;
		}
	}
}
